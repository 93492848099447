import styled from 'styled-components'
import { Link } from 'gatsby'

export const ParaLink = styled(Link)`
  color: ${({ theme }) => theme.colors.indigo};
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: ${({ theme }) => theme.spacings.xSmall};
  text-decoration-color: ${({ theme }) => theme.colors.spanish};

  &:focus {
    color: ${({ theme }) => theme.colors.indigo};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.pink};
    transition: ${({ theme }) => theme.animations.trans1};
    text-decoration-color: ${({ theme }) => theme.colors.pink};
  }
`
