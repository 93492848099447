import React from 'react'
import { graphql } from 'gatsby'

// Master Header
import { P } from '../../theme/Typography'

// Master Link
import { ParaLink } from '../../theme/Link'
import BrandLayout from '../components/Branding/BrandLayout'

const Branding = query => {
  const items = query.data.allMdx.edges

  return (
    <>
      <BrandLayout title={'Branding'} subTitle={'Site Guidelines'}>
        <P>
          Bacon ipsum dolor amet boudin pastrami porchetta, tongue chislic cow meatball. Cow doner
          jerky alcatra. Fatback pork chop pancetta tri-tip, biltong short ribs salami hamburger
          jowl jerky cupim drumstick tongue capicola. Chislic salami flank strip steak t-bone
          meatloaf short ribs, short loin jerky capicola chicken shank frankfurter chuck pork belly.
        </P>
        {items.map((item, key) => {
          const { title, slug } = item.node.frontmatter
          return (
            <React.Fragment key={key}>
              <P>
                <ParaLink to={`/branding/${slug}`}>{title}</ParaLink>
              </P>
            </React.Fragment>
          )
        })}
        <P>
          Ham hock prosciutto pork short loin pork belly. Brisket jowl spare ribs ribeye pork chop
          tail fatback drumstick ham turducken ball tip cow pancetta. Turducken corned beef meatloaf
          frankfurter jerky hamburger cow bresaola tenderloin pork loin chuck andouille shoulder
          spare ribs capicola. Cow strip steak beef, corned beef hamburger flank short loin shankle
          shank boudin t-bone fatback bacon. Leberkas pastrami chicken meatball, pork chop pig
          pancetta kevin capicola bresaola turducken strip steak prosciutto. Porchetta tenderloin
          picanha pork strip steak ribeye bacon pastrami swine. Short loin porchetta fatback t-bone
          ground round pork chop. Biltong ground round sirloin leberkas. Pastrami corned beef jowl
          picanha capicola salami.
        </P>
      </BrandLayout>
    </>
  )
}

export const query = graphql`
  {
    allMdx(filter: { fileAbsolutePath: { regex: "/branding/" } }) {
      edges {
        node {
          frontmatter {
            title
            subTitle
            slug
          }
        }
      }
    }
  }
`

export default Branding
