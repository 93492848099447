import React from 'react'
import styled from 'styled-components'

// Components
import Seo from '../Seo'
import BreadCrumb from './BrandCrumb'
import Header from '../Header'
import PageHeader from '../Header/PageHeader'
import Curve from '../Curve'
import Footer from '../Footer/'

const BrandWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.ghost};
  padding: ${({ theme }) => theme.spacings.large};
`

const BrandContainer = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.md};
  margin: auto;
`

const GapAid = styled.div`
  width: 100%;
  /* min-height: 20vh; */
  background: white;
`

const BrandLayout = ({ title, subTitle, crumb, children }) => {
  // const heightGap = () => {
  //   const bh = window.innerHeight
  //   const fc = document.getElementById('footerContainer')
  //   console.log(fc)
  //   const fcBounds = 0 || fc.getBoundingClientRect()
  //   const fcBottom = fcBounds.bottom
  //   const totalNeeded = bh - fcBottom
  //   const gap = document.getElementById('gapAid')
  //   if (totalNeeded > 0) gap.setAttribute('style', `height: ${totalNeeded}px`)
  // }

  // useEffect(() => heightGap(), [])
  // if (typeof window !== 'undefined') window.addEventListener('resize', heightGap)

  return (
    <>
      <Seo title={title} />
      <Header />
      <PageHeader title={title} subTitle={subTitle} />
      <Curve />
      <BrandWrapper>
        <BrandContainer>
          {crumb && <BreadCrumb crumb={crumb} />}
          {children}
        </BrandContainer>
      </BrandWrapper>
      <GapAid />
      <Curve flip />
      <Footer />
    </>
  )
}

export default BrandLayout
